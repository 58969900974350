<template>
  <div class="box has-max-width">
    <h3 class="title is-5 push-bottom-xxs">{{ $t('profile.account.information.title') }}</h3>
    <p v-html="$t('profile.account.information.helpText')"></p>

    <form v-if="isPopulated" @submit.prevent="update">
      <div class="field">
        <label class="label">{{ $t('general.firstName') }}</label>

        <div class="control">
          <input
            v-model="firstName"
            :disabled="isLoading"
            class="input"
            type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('general.lastName') }}</label>

        <div class="control">
          <input
            v-model="lastName"
            :disabled="isLoading"
            class="input"
            type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('profile.account.information.cellphoneNumber') }}</label>

        <div class="control">
          <input
            v-model="phoneNumber"
            :disabled="isLoading"
            class="input"
            type="text" />
        </div>
      </div>

      <div class="field is-grouped">
        <div style="display: flex; align-items: center;">
          <div class="control">
            <button :class="{ 'is-loading' : isLoading }" class="button is-primary">
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isPopulated: false
    }
  },

  static () {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: ''
    }
  },

  computed: {
    user () {
      return this.$store.getters['user/data']
    }
  },

  mounted () {
    this.populate()
  },

  methods: {
    populate () {
      if (this.user) {
        this.firstName = this.user.firstName
        this.lastName = this.user.lastName
        this.phoneNumber = this.user.phoneNumber

        this.isPopulated = true
      }
    },

    async update () {
      this.isLoading = true

      try {
        await this.$store.dispatch('user/update', {
          id: this.user.id,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.isLoading = false
    }
  }
}
</script>
